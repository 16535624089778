.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
.load {
  -webkit-animation: slide 2s;
  animation: slide 2s;
}
.logo {
  width: 30%;
  height: auto;
  margin: 0 auto;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/********************
*****14. Animation*********
********************/
@-webkit-keyframes slide {
  0% {
    background-image: url("../public/images/equity.jpg");
  }
  33% {
    background-image: url("../public/images/fixed-income.jpeg");
  }
  33.1% {
    background-image: url("../public/images/fixed-income.jpeg");
  }
  66% {
    background-image: url("../public/images/oil.jpg");
  }
  66.1% {
    background-image: url("../public/images/oil.jpg");
  }
  80% {
    background-image: url("../public/images/priv-equity.jpg");
  }
  80.1% {
    background-image: url("../public/images/priv-equity.jpg");
  }
  99% {
    background-image: url("../public/images/equity.jpg");
  }
  99.1% {
    background-image: url("../public/images/equity.jpg");
  }
  100% {
    background-image: url("../public/images/equity.jpg");
  }
}
@keyframes slide {
  0% {
    background-image: url("../public/images/equity.jpg");
  }
  33% {
    background-image: url("../public/images/fixed-income.jpeg");
  }
  33.1% {
    background-image: url("../public/images/fixed-income.jpeg");
  }
  66% {
    background-image: url("../public/images/oil.jpg");
  }
  66.1% {
    background-image: url("../public/images/oil.jpg");
  }
  80% {
    background-image: url("../public/images/priv-equity.jpg");
  }
  80.1% {
    background-image: url("../public/images/priv-equity.jpg");
  }
  99% {
    background-image: url("../public/images/equity.jpg");
  }
  99.1% {
    background-image: url("../public/images/equity.jpg");
  }
  100% {
    background-image: url("../public/images/equity.jpg");
  }
}

/*******************ContactForm*******************/
.contact-form {
  width: 50%;
  padding: 1%;
}
#map {
  padding: 1%;
}
#map iframe {
  height: 100%;
  width: 388px;
  margin: 0 auto;
}
@media screen and (max-width: 901px) {
  .contact-form {
    width: 100%;
  }
  #map {
    visibility: hidden;
    display: none;
  }
}
input[type="text"],
input[type="email"],
select,
.contact-form textarea {
  width: 100%;
  padding: 1%;
  background: rgba(218, 237, 255, 1);
  border: 1px solid rgba(20, 104, 168, 1);
  border-radius: 5px;
  margin: 20px 0 0 0;
  resize: vertical;
}
input[type="text"]:hover,
input[type="text"]:focus,
input[type="email"]:hover,
input[type="email"]:focus,
.contact-form textarea:hover,
.contact-form textarea:focus,
select:hover,
select:focus,
textarea:hover,
textarea:hover {
  -webkit-box-shadow: 0 0 10px #fff;
  box-shadow: 0 0 10px #fff;
}
.contact-form textarea,
input[type="textarea"] {
  height: 15vh;
}

/*******************
*******Go to Top*****
********************/

.to-top {
  bottom: 70px;
  background-color: rgba(26, 147, 239, 0.8) !important;
  box-shadow: inset 7px 5px 46px -12px rgba(20, 104, 168, 1) !important;
}
.to-top:hover,
.to-top:focus {
  background-color: rgba(20, 104, 168, 1) !important;
}
/*******************
******* Cookies Banner *****
********************/
.alert-cookie {
  bottom: 0px;
  padding: 0px 20px;
  align-items: baseline;
  background-color: rgba(6, 28, 70, 0.8);
  color: white;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  left: 0px;
  position: fixed;
  width: 100%;
  z-index: 999;
}
.alert-cookie a {
  color: rgba(26, 147, 239, 0.8);
  text-shadow: rgba(255, 255, 255, 0.5) 0.3px 0.3px 0.5px;
}
.alert-cookie a:hover,
.alert-cookie a:focus {
  color: rgba(26, 147, 239, 0.8);
  text-shadow: none;
}
.btn-cookie {
  background: rgba(26, 147, 239, 0.8);
  color: white;
  font-size: 16px;
  border-radius: 5px;
  border: 0px;
  box-shadow: rgba(255, 255, 255, 0.5) 0.3px 0.3px 0.5px;
  cursor: pointer;
  flex: 0 0 auto;
  padding: 5px 10px;
  margin: 15px;
}
.btn-cookie:hover,
.btn-cookie:focus {
  background: rgba(20, 104, 168, 1);
  border: 0px;
}

@media screen and (max-width: 769px) {
  .alert-cookie {
    padding: 15px 20px;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
}

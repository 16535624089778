body {
  margin: 0;
  padding: 0;
  font-family: Arial, Helvetica, sans-serif;
  color: #ffffff;
  font-size: 16px;
  line-height: 1.5;
  height: 100%;
}
*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.is-modal-open {
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  padding: 0;
}

ul,
ol {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
  color: #ffffff;
}
a:hover,
a:focus {
  text-decoration: none;
  color: rgba(27, 153, 232, 1);
}
span {
  color: rgba(27, 153, 232, 1);
}
img {
  display: block;
  width: 100%;
  height: auto;
  margin: 0 auto;
}

button {
  padding: 0;
  font-family: inherit;
  height: fit-content;
  width: fit-content;
  border: none;
  outline: none;
}

input {
  font-family: inherit;
  outline: none;
}
main {
  margin-top: 80px;
}
section {
  height: auto;
  width: 100%;
  margin: 0;
  background: #ffffff;
  color: #062134;
  font-size: 18px;
}
